<script lang="ts">
  import Checkbox from '@smui/checkbox/styled';
  import FormField from "@smui/form-field/styled";
  import Textfield from "@smui/textfield/styled";
  import Radio from "@smui/radio/styled";
  import Button from "@smui/button/styled";
  import LayoutGrid, { Cell } from "@smui/layout-grid/styled";
  import Paper, { Content } from "@smui/paper/styled";
  import Collection from "../../util/Collection.svelte";
  import { formatPercentage } from "../../util/format";
  import { addDoc, collection, doc, getFirestore, orderBy, setDoc } from "@firebase/firestore";
  import { getAuth, onAuthStateChanged } from "@firebase/auth";
  import type { User } from "@firebase/auth";

  const queryConstraints = [orderBy('startedAt', 'desc')];

  function strategyToString(parameters) {
    if (parameters.limit) {
      return `TOP ${parameters.limit} ${parameters.reBalance}`;
    }
    if (parameters.threshold) {
      return `GTE ${parameters.threshold} ${parameters.reBalance}`;
    }
    return `unbekannt`;
  }

  //@ts-ignore
  const formatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
  });
  function formatTimestamp(ts) {
    return formatter.format(ts.toDate());
  }

  let comment = '';

  let txFee = 0.0056;
  $: txFeeInvalid = isNaN(txFee) || txFee <= 0;

  let limit = 10;
  $: limitInvalid = isNaN(limit) || limit <= 0;

  let threshold = 75;
  $: thresholdInvalid = isNaN(threshold) || threshold <= 0;

  let strategy = 'limit';
  let reBalance = 'weekly';

  let isLoading = false;

  function strategyOption() {
    if (strategy === 'limit') {
      return { limit };
    }
    return { threshold };
  }

  function getLoggedInUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(getAuth(), user => {
        if (user) {
          resolve(user);
        } else {
          reject();
        }
      });
    });
  }

  function buildBacktestCreatedMessage(data: any) {
    let message = `The backtest has been created! `;
    if (data.notificationEmail) {
      message += `Once finished, you will get an email to ${data.notificationEmail}`;
    }
    return message;
  }

  function buildBacktestConfirmMessage(data: any) {
    let message = 'Start a backtest now? ';
    if (data.notificationEmail) {
      message += `Once finished, you will get an email to ${data.notificationEmail}.`;
    }
    return message;
  }

  async function createBacktest() {
    isLoading = true;

    const user = await getLoggedInUser();

    const data: any = {
      comment,
      state: 'NEW',
      startedAt: new Date(),
      parameters: {
        txFee,
        reBalance,
        ...strategyOption(),
      },
    };

    if (user?.emailVerified) {
      data.notificationEmail = user.email;
    }

    const ok = confirm(buildBacktestConfirmMessage(data));
    if (!ok) {
      isLoading = false;
      return;
    }

    await addDoc(
      collection(getFirestore(), 'backtest'),
      data as any,
    );

    alert(buildBacktestCreatedMessage(data));

    isLoading = false;
  }

  function getAnnualReturns(backtest) {
    const { snapshots } = backtest;
    const records = [];

    const years = Object.keys(snapshots).sort();
    for (let i = 1; i < years.length; i++) {
      const lastYear = years[i - 1]!;
      const newYear = years[i]!;
      const returns = snapshots[newYear]! / snapshots[lastYear]! - 1;
      records.push({ year: lastYear, returns });
    }

    return records;
  }

  let showArchived = false;

  async function archive(backtest) {
    await setDoc(
      doc(getFirestore(), 'backtest', backtest.id),
      { archived: true },
      { merge: true }
    );
  }

  async function unarchive(backtest) {
    await setDoc(
      doc(getFirestore(), 'backtest', backtest.id),
      { archived: true },
      { merge: true }
    );
  }
</script>

<LayoutGrid>
  <Collection path="backtest" {queryConstraints} let:data={backtests} let:ref={ref}>
    <Cell span={12}>
      <h2 class="h4">
        Create new backtest
      </h2>
      <div class="my">
        <span class="mr">Comment (optional):</span>
        <Textfield bind:value={comment} />
      </div>
      <div class="my">
        <span class="mr">TX Fee:</span>
        <Textfield bind:value={txFee} invalid={txFeeInvalid} type="number" />
      </div>
      <div class="my">
        Strategy:
        <br>
        <FormField>
          <Radio bind:group={strategy} value="limit"/>
          <span class="mr">Top</span>
          <Textfield bind:value={limit} invalid={limitInvalid} type="number" disabled={strategy !== 'limit'} />
          <span class="ml">stocks</span>
        </FormField>
        <br>
        <FormField>
          <Radio bind:group={strategy} value="threshold"/>
          <span class="mr">Stocks with score &ge; </span>
          <Textfield bind:value={threshold} invalid={thresholdInvalid} type="number" disabled={strategy !== 'threshold'} />
        </FormField>
      </div>
      <div class="my">
        Rebalance:
        <FormField>
          <Radio bind:group={reBalance} value="quarterly"/> Quarterly
        </FormField>
        <FormField>
          <Radio bind:group={reBalance} value="monthly"/> Monthly
        </FormField>
        <FormField>
          <Radio bind:group={reBalance} value="weekly"/> Weekly
        </FormField>
        <FormField>
          <Radio bind:group={reBalance} value="daily"/> Daily
        </FormField>
      </div>
      <Button on:click={createBacktest} variant="raised" disabled={isLoading}>Create</Button>

      <hr class="my">
      <FormField>
        <Checkbox bind:checked={showArchived} />
        <span slot="label">Show archived</span>
      </FormField>
    </Cell>

    {#each backtests as backtest}
      {#if !backtest.archived || showArchived}
        <Cell span={4}>
        <Paper elevation={4}>
          <Content>
            {#if backtest.comment}
              <p>
                <i>{backtest.comment}</i>
              </p>
            {/if}
            <h2 class="h4">
              {backtest.state}
              {#if backtest.startedAt}
                <span class="secondary">
                  - started: {formatTimestamp(backtest.startedAt)}
                </span>
              {/if}
            </h2>
            <p>
              {#if backtest.archived}
                <Button on:click={() => unarchive(backtest)}>unarchive</Button>
              {:else}
                <Button on:click={() => archive(backtest)}>archive</Button>
              {/if}
            </p>
            {#if backtest.snapshots}
              <p>
                Returns:
                <br>
                {#each getAnnualReturns(backtest) as record}
                  {record.year}: {formatPercentage(record.returns)}
                  <br>
                {/each}
              </p>
            {/if}
            <p>
              Parameters:
              <br>
              TX Fee: {formatPercentage(backtest.parameters.txFee)}
              <br>
              Strategy: {strategyToString(backtest.parameters)}
            </p>
            <p>
              <a href="https://docs.google.com/spreadsheets/d/{backtest.spreadsheetId}/" target="_blank">
                View in Google Sheets
              </a>
            </p>
          </Content>
        </Paper>
      </Cell>
      {/if}
    {/each}

  </Collection>
</LayoutGrid>
