<script lang="ts">
  import { Router, Route } from "yrv";
  import { onAuthStateChanged, getAuth } from "@firebase/auth";
  import type { User } from "@firebase/auth";
  import NavBar from "./NavBar.svelte";
  import NewsletterPage from "./pages/newsletter/NewsletterPage.svelte";
  import InternalHomePage from "./pages/InternalHomePage.svelte";
  import DraftList from "./pages/draft-newsletter/DraftList.svelte";
  import DraftEditor from "./pages/draft-newsletter/DraftEditor.svelte";
  import DraftPreview from "./pages/draft-newsletter/DraftPreview.svelte";
  import InvestorList from "./pages/investors/InvestorList.svelte";
  import InvestorEditor from "./pages/investors/InvestorEditor.svelte";
  import LoginPage from "./pages/LoginPage.svelte";
  import AddCompaniesByISIN from "./pages/companies/AddCompaniesByISIN.svelte";
  import ManualCompanyList from "./pages/companies/ManualCompanyList.svelte";
  import BacktestsPage from "./pages/backtests/BacktestsPage.svelte";
  import CSV2SQL from "./pages/CSV2SQL.svelte";
  import DebugNotifications from "./pages/DebugNotifications.svelte";
  import { afterInitialize, initializeFirebaseApp } from "./initialize";

  initializeFirebaseApp();

  let user: User|null|undefined;

  afterInitialize(() =>
    onAuthStateChanged(getAuth(), newUser => {
      user = newUser;
    })
  );
</script>

<svelte:head>
  <!-- Material Icons -->
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
  />
  <!-- Roboto -->
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700"
  />
  <!-- Roboto Mono -->
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Roboto+Mono"
  />
  <!-- 
      Inline the Web-Vitals polyfill script
      See: https://github.com/GoogleChrome/web-vitals#load-the-library
    -->
  <script>__WEB_VITALS_POLYFILL__
  </script>
</svelte:head>

{#if user}
  <Router let:router>
    <NavBar {user} />
    <main>
      <Route exact>
        <InternalHomePage/>
      </Route>
      <Route exact path="/newsletter">
        <NewsletterPage/>
      </Route>
      <Route exact path="/draft-newsletter">
        <DraftList/>
      </Route>
      <Route path="/draft-newsletter/:id">
        <DraftEditor {user} newsletterId={router.params.id}/>
      </Route>
      <Route path="/preview-newsletter/:id">
        <DraftPreview {user} newsletterId={router.params.id}/>
      </Route>
      <Route exact path="/investors">
        <InvestorList/>
      </Route>
      <Route path="/investors/:id">
        <InvestorEditor/>
      </Route>
      <Route path="/add-companies-by-isin">
        <AddCompaniesByISIN/>
      </Route>
      <Route path="/manual-lists">
        <ManualCompanyList/>
      </Route>
      <Route path="/backtests">
        <BacktestsPage/>
      </Route>
      <Route path="/csv2sql">
        <CSV2SQL />
      </Route>
      <Route path="/debug-notifications">
        <DebugNotifications />
      </Route>
    </main>
  </Router>
{:else if user === null}
  <Router>
    <NavBar user={null} />
    <main>
      <Route exact fallback>
        <LoginPage user={null} />
      </Route>
    </main>
  </Router>
{/if}

<style lang="scss">
  @use '@material/typography/index' as typography;

  .h4 {
    @include typography.typography("headline4");
  }
</style>
