<script>
  import Button from "@smui/button/styled";
  import { parse } from "csv-parse/browser/esm/sync";

  let isin;
  let currency;
  let csv;

  let ok = false;
  let error;

  function recordToSQLStatement(record) {
    const { Date,Close,Volume } = record;
    return `insert into stock_prices (isin, date, price, volume, currency) values ('${isin}', '${Date}', ${Close}, ${Volume}, '${currency}') on conflict (isin, date) do update set isin='${isin}', date='${Date}', price=${Close}, volume=${Volume}, currency='${currency}';`;
  }

  function transform() {
    ok = false;
    error = null;
    try {
      const records = parse(csv, {columns: true});
      const sqlStatements = records.map(recordToSQLStatement);
      const sql = sqlStatements.join('\n');
      navigator.clipboard.writeText(sql);
      ok = true;
    } catch (e) {
      error = e;
    }
  }
</script>

<input
  type="text"
  bind:value={currency}
  placeholder="Currency"
/>
<input
  type="text"
  bind:value={isin}
  placeholder="ISIN"
/>

<Button
  on:click={transform}
>
  transform
</Button>

<div>
  {#if ok}
    Ok!
  {/if}
  {#if error}
    {error}
  {/if}
</div>

<textarea bind:value={csv} style="min-width: 85%"></textarea>
