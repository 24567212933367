export const environment = {
  FIREBASE_CONFIG: {
    "apiKey": "AIzaSyDmjOnKtCo1tRUO61JbTYvLWjvxealikiU",
    "authDomain": "insiderpie-test.firebaseapp.com",
    "projectId": "insiderpie-test",
    "storageBucket": "insiderpie-test.appspot.com",
    "messagingSenderId": "249660922996",
    "appId": "1:249660922996:web:994ffb015f6eee37cdc521",
    "measurementId": "G-84JFFJ27Y3"
  },
  FIREBASE_APPCHECK_DEBUG_TOKEN: "614bc387-8792-452b-a1d0-e038f47af544",
  FIREBASE_TARGET: "test",
  NODE_ENV: "production",
  MEILI_HOST: "search-test.insiderpie.de",
};
